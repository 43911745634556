<template>
  <div>
    <v-layout wrap justify-center pt-16 pb-16>
      <v-flex xs12 lg10 align-self-center>
        <v-carousel
          cycle
          :min-height="
            $vuetify.breakpoint.name == 'xs'
              ? '450'
              : $vuetify.breakpoint.name == 'sm'
              ? '450'
              : $vuetify.breakpoint.name == 'md'
              ? '400'
              : $vuetify.breakpoint.name == 'lg'
              ? '400'
              : '400'
          "
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(item, i) in testimonials.data" :key="i" :src="item.src">
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center text-center>
                <span class="bantitle9"> {{ testimonials.topCaption }} </span>
              </v-flex>
              <v-flex xs12 py-2 align-self-center text-center>
                <span class="bantitle7"><b> {{ testimonials.title }} </b></span>
              </v-flex>
              <v-flex xs12 align-self-center text-center>
                <v-layout wrap justify-center>
                  <v-flex sm10 md8 lg6 xl5 align-self-center text-center px-6>
                    <span v-html="item.text" class="bantitle8">
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 py-0 py-md-5 py-lg-12 text-center>
                <v-layout wrap justify-center>
                  <v-flex xs12 lg12 align-self-center text-center>
                    <v-list-item>
                      <!-- <v-list-item-avatar size="50">
                        <v-img contain :src="mediaURL+item.image">
                          <template v-slot:placeholder>
                            <ImageLoader />
                          </template>
                        </v-img>
                      </v-list-item-avatar> -->

                      <v-list-item-content>
                        <v-list-item-title class="text-center">
                          <span class="bantitle10"> {{ item.name }} </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                          <span class="bantitle11">
                            {{ item.company }}
                          </span></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props:["testimonials"],
  data() {
    return {
      avat: require("./../../assets/Home/image2.png"),
    };
  },
};
</script>