<template>
  <div class="gradientB">
    <v-layout wrap justify-center pt-8 v-if="top">
      <v-flex xs12 sm11 md11 lg11 py-12 pl-sm-16 align-self-center>
        <v-img
          class="align-center"
          height="100vh"
          src="../../assets/Home/g1.svg"
        >
          <v-layout wrap justify-center fill-height>
            <v-flex xs12 lg7 align-self-center text-center pl-lg-6 pl-xl-16>
              <p
                style="color: #fff; font-family: TitilliumBold"
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xs'
                      ? '60px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '80px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '91px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '125px'
                      : '140px',
                }"
              >
                Innovation
              </p>

              <p
                style="
                  color: #fff;
                  font-family: TitilliumBold;
                  line-height: 10px !important;
                "
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xs'
                      ? '60px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '80px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '91px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '125px'
                      : '140px',
                }"
              >
                Perfected
              </p>
              <p
                class="pt-6 pl-3 pl-sm-0"
                style="
                  color: #cbcbcb;
                  font-family: TitilliumBold;
                  line-height: 100px !important;
                "
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xs'
                      ? '16px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '18px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '16px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '18px'
                      : '20px',

                  'line-height':
                    $vuetify.breakpoint.name == 'xs'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'xl'
                      ? '64px'
                      : '50px',
                }"
              >
                {{ top.caption }}
              </p>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
      <v-flex
        xs12
        sm1
        md1
        lg1
        align-self-center
        text-center
        pa-sm-5
        pa-md-8
        pa-lg-12
      >
        <v-layout wrap justify-center>
          <v-flex
            xs2
            sm12
            px-4
            px-sm-0
            lg12
            py-4
            align-self-center
            v-for="(item, i) in socialMediasList"
            :key="i"
          >
            <a :href="item.link" target="_blank">
              <v-img
                contain
                height="21px"
                width="21px"
                :src="
                  webpSupported && item.image.split('.').pop() !== 'svg'
                    ? mediaURL + item.image
                    : mediaURLold + item.image
                "
              >
                <template v-slot:placeholder>
                  <ImageLoader />
                </template>
              </v-img>
            </a>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex
        xs12
        :py-16="$vuetify.breakpoint.name == 'xs' ? true : false"
        pa-2
        pa-md-16
      >
        <v-layout wrap justify-center>
          <!-- <v-flex xs12 py-3 align-self-center text-center>
            <span class="bantitle">{{ areas.topCaption }}</span>
          </v-flex> -->
          <v-flex xs12 align-self-center text-center>
            <span
              :class="
                $vuetify.breakpoint.name == 'xs' ? 'bantitle2xs' : 'bantitle2'
              "
              >{{ areas.title }}</span
            >
          </v-flex>
          <v-flex xs12 sm8 lg4 align-self-center text-center>
            <span class="bantitle3">
              {{ areas.caption }}
            </span>
          </v-flex>
          <v-flex xs12 py-6 align-self-center text-center>
            <v-btn @click="$router.push('/services')" plain text>
              <span class="btntitle text-none">
                Explore all &nbsp;
                <v-icon color="white">mdi-arrow-right</v-icon></span
              >
            </v-btn>
          </v-flex>
          <v-flex xs12 align-self-center>
            <Cards :areas="areas.data" />
          </v-flex>

          <v-flex xs12 py-3 align-self-center text-center pt-8>
            <span class="bantitle4">{{ products.topCaption }}</span>
          </v-flex>
          <v-flex xs12 align-self-center text-center>
            <span
              :class="
                $vuetify.breakpoint.name == 'xs' ? 'bantitle2xs' : 'bantitle2'
              "
              >{{ products.title }}</span
            >
          </v-flex>
          <v-flex xs12 sm7 md6 lg4 align-self-center text-center>
            <span class="bantitle3">
              {{ products.caption }}
            </span>
          </v-flex>
          <v-flex xs12 md11 lg10 xl11 align-self-center>
            <!-- <v-img contain :src="require('./../../assets/Home/banner.png')"> -->
            <v-img
              contain
              :src="
                webpSupported
                  ? mediaURL + products.image
                  : mediaURLold + products.image
              "
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-flex>

          <!-- start- Last Part -->
          <v-flex xs12 align-self-center class="orangGrad">
            <v-layout wrap>
              <v-flex xs12 px-sm-4 sm6 md6 lg6 align-self-center>
                <v-layout wrap>
                  <v-flex
                    xs12
                    py-4
                    align-self-center
                    :text-center="
                      $vuetify.breakpoint.name == 'xs' ? true : false
                    "
                    :text-justify="
                      $vuetify.breakpoint.name !== 'xs' ? true : false
                    "
                  >
                    <v-layout wrap justify-center>
                      <v-flex lg12 align-self-center>
                        <span
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'bantitle5xs'
                              : 'bantitle5'
                          "
                        >
                          {{ products.leftTextTitle }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 align-self-center text-justify>
                    <v-layout wrap justify-center>
                      <v-flex px-3 px-sm-0 lg12 align-self-center>
                        <span
                          v-html="products.leftTextDescription"
                          :class="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'bantitle6xs'
                              : 'bantitle6'
                          "
                        >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    :text-center="
                      $vuetify.breakpoint.name == 'xs' ? true : false
                    "
                    py-8
                    align-self-center
                  >
                    <v-btn
                      class="btnn"
                      color="white"
                      outlined
                      rounded
                      @click="
                        $router.push(
                          '/products?category=612dae376c74ee79eea1e3e0'
                        )
                      "
                    >
                      <span class="text-none"> View Portfolio </span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 align-self-center>
                <v-img
                  contain
                  :src="
                    webpSupported
                      ? mediaURL + products.rightImage
                      : mediaURLold + products.rightImage
                  "
                >
                  <template v-slot:placeholder>
                    <ImageLoader />
                  </template>
                </v-img>
                <!-- <v-carousel
                  v-model="slideNo"
                  continuous
                  cycle
                  :interval="slideInterval"
                  show-arrows-on-hover
                  hide-delimiter-background
                :height="sliderHeight"
                  delimiter-icon="mdi-minus"
                >
                  <v-carousel-item v-for="(item, i) in sliderData" :key="i">
                    <v-card flat color="transparent">
                      <v-img :src="getImgUrl(item.image)" width="100%" contain>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#FF2323"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-carousel-item>
                </v-carousel> -->
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- end Last Part -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import Cards from "@/components/Home2/Cards";
export default {
  props: ["top", "products", "areas"],
  components: {
    Cards,
  },
  data() {
    return {
      socialMediasList: [],
      slideInterval: 10000,
      slideNo: 0,
      webpSupported: true,
      sliderData: [
        {
          image: "parambikulam.png",
        },
        {
          image: "styloop.png",
        },
        {
          image: "periyar.png",
        },
      ],
    };
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then((r) => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(
        () => true,
        () => false
      );
    })();
  },
  mounted() {
    this.getSocialMediaData();
  },
  computed: {
    sliderHeight() {
      var height = "auto";
      // if (this.$vuetify.breakpoint.xl) height = "450px";
      // if (this.$vuetify.breakpoint.lg) height = "500px";
      // if (this.$vuetify.breakpoint.md) height = "400px";
      // if (this.$vuetify.breakpoint.sm) height = "350px";
      if (this.$vuetify.breakpoint.xs) height = "300px";
      return height;
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/Slider/" + pic);
    },
    getSocialMediaData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/socialmedia/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.socialMediasList = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
.gradientB {
  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  /* background-image: linear-gradient(rgb(30,144,255) 0%, transparent 100%); */
  background-image: transparent
    linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  background-color: black;
}
.orangGrad {
  background-image: transparent
    linear-gradient(180deg, #f5a624 0%, #ffc837 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
}
</style>