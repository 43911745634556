var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gradientB"},[(_vm.top)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-8":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":"","md11":"","lg11":"","py-12":"","pl-sm-16":"","align-self-center":""}},[_c('v-img',{staticClass:"align-center",attrs:{"height":"100vh","src":require("../../assets/Home/g1.svg")}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","lg7":"","align-self-center":"","text-center":"","pl-lg-6":"","pl-xl-16":""}},[_c('p',{staticStyle:{"color":"#fff","font-family":"TitilliumBold"},style:({
                'font-size':
                  _vm.$vuetify.breakpoint.name == 'xs'
                    ? '60px'
                    : _vm.$vuetify.breakpoint.name == 'sm'
                    ? '80px'
                    : _vm.$vuetify.breakpoint.name == 'md'
                    ? '91px'
                    : _vm.$vuetify.breakpoint.name == 'lg'
                    ? '125px'
                    : '140px',
              })},[_vm._v(" Innovation ")]),_c('p',{staticStyle:{"color":"#fff","font-family":"TitilliumBold","line-height":"10px !important"},style:({
                'font-size':
                  _vm.$vuetify.breakpoint.name == 'xs'
                    ? '60px'
                    : _vm.$vuetify.breakpoint.name == 'sm'
                    ? '80px'
                    : _vm.$vuetify.breakpoint.name == 'md'
                    ? '91px'
                    : _vm.$vuetify.breakpoint.name == 'lg'
                    ? '125px'
                    : '140px',
              })},[_vm._v(" Perfected ")]),_c('p',{staticClass:"pt-6 pl-3 pl-sm-0",staticStyle:{"color":"#cbcbcb","font-family":"TitilliumBold","line-height":"100px !important"},style:({
                'font-size':
                  _vm.$vuetify.breakpoint.name == 'xs'
                    ? '16px'
                    : _vm.$vuetify.breakpoint.name == 'sm'
                    ? '18px'
                    : _vm.$vuetify.breakpoint.name == 'md'
                    ? '16px'
                    : _vm.$vuetify.breakpoint.name == 'lg'
                    ? '18px'
                    : '20px',

                'line-height':
                  _vm.$vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : _vm.$vuetify.breakpoint.name == 'xl'
                    ? '64px'
                    : '50px',
              })},[_vm._v(" "+_vm._s(_vm.top.caption)+" ")])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm1":"","md1":"","lg1":"","align-self-center":"","text-center":"","pa-sm-5":"","pa-md-8":"","pa-lg-12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},_vm._l((_vm.socialMediasList),function(item,i){return _c('v-flex',{key:i,attrs:{"xs2":"","sm12":"","px-4":"","px-sm-0":"","lg12":"","py-4":"","align-self-center":""}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('v-img',{attrs:{"contain":"","height":"21px","width":"21px","src":_vm.webpSupported && item.image.split('.').pop() !== 'svg'
                  ? _vm.mediaURL + item.image
                  : _vm.mediaURLold + item.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1)])}),1)],1),_c('v-flex',{attrs:{"xs12":"","py-16":_vm.$vuetify.breakpoint.name == 'xs' ? true : false,"pa-2":"","pa-md-16":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'xs' ? 'bantitle2xs' : 'bantitle2'},[_vm._v(_vm._s(_vm.areas.title))])]),_c('v-flex',{attrs:{"xs12":"","sm8":"","lg4":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"bantitle3"},[_vm._v(" "+_vm._s(_vm.areas.caption)+" ")])]),_c('v-flex',{attrs:{"xs12":"","py-6":"","align-self-center":"","text-center":""}},[_c('v-btn',{attrs:{"plain":"","text":""},on:{"click":function($event){return _vm.$router.push('/services')}}},[_c('span',{staticClass:"btntitle text-none"},[_vm._v(" Explore all   "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-arrow-right")])],1)])],1),_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('Cards',{attrs:{"areas":_vm.areas.data}})],1),_c('v-flex',{attrs:{"xs12":"","py-3":"","align-self-center":"","text-center":"","pt-8":""}},[_c('span',{staticClass:"bantitle4"},[_vm._v(_vm._s(_vm.products.topCaption))])]),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'xs' ? 'bantitle2xs' : 'bantitle2'},[_vm._v(_vm._s(_vm.products.title))])]),_c('v-flex',{attrs:{"xs12":"","sm7":"","md6":"","lg4":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"bantitle3"},[_vm._v(" "+_vm._s(_vm.products.caption)+" ")])]),_c('v-flex',{attrs:{"xs12":"","md11":"","lg10":"","xl11":"","align-self-center":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.webpSupported
                ? _vm.mediaURL + _vm.products.image
                : _vm.mediaURLold + _vm.products.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1),_c('v-flex',{staticClass:"orangGrad",attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","px-sm-4":"","sm6":"","md6":"","lg6":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","py-4":"","align-self-center":"","text-center":_vm.$vuetify.breakpoint.name == 'xs' ? true : false,"text-justify":_vm.$vuetify.breakpoint.name !== 'xs' ? true : false}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"lg12":"","align-self-center":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'bantitle5xs'
                            : 'bantitle5'},[_vm._v(" "+_vm._s(_vm.products.leftTextTitle)+" ")])])],1)],1),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-justify":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"px-3":"","px-sm-0":"","lg12":"","align-self-center":""}},[_c('span',{class:_vm.$vuetify.breakpoint.name == 'xs'
                            ? 'bantitle6xs'
                            : 'bantitle6',domProps:{"innerHTML":_vm._s(_vm.products.leftTextDescription)}})])],1)],1),_c('v-flex',{attrs:{"xs12":"","text-center":_vm.$vuetify.breakpoint.name == 'xs' ? true : false,"py-8":"","align-self-center":""}},[_c('v-btn',{staticClass:"btnn",attrs:{"color":"white","outlined":"","rounded":""},on:{"click":function($event){return _vm.$router.push(
                        '/products?category=612dae376c74ee79eea1e3e0'
                      )}}},[_c('span',{staticClass:"text-none"},[_vm._v(" View Portfolio ")])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","align-self-center":""}},[_c('v-img',{attrs:{"contain":"","src":_vm.webpSupported
                    ? _vm.mediaURL + _vm.products.rightImage
                    : _vm.mediaURLold + _vm.products.rightImage},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }