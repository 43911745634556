<template>
  <div>
    <v-layout wrap justify-center py-16>
      <v-flex xs12 md11 lg10 pa-3 >
        <v-layout wrap>
          <v-flex sm6 md4 lg4 pt-4 pl-0 pl-sm-4 v-for="(item, i) in areas" :key="i">
            <v-card class="hov" @mouseover="item.color=true" @mouseleave="item.color=false"  @click="$router.push('/products?category=' + item._id)"  dark elevation="24">
              <v-img
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :contain="webpSupported?true:false"
                :height="webpSupported?'auto':'500px'"
                class="align-end"
                :src="webpSupported && item.image.split('.').pop() !== 'svg'? mediaURL+item.homeImage : mediaURLold+item.homeImage"
              >
                <template v-slot:placeholder>
                  <ImageLoader />
                </template>
                <v-card :hover="true"  flat color="transparent">
                  <v-layout wrap justify-center pa-4>
                    <v-flex
                   
                      :key="i"
                      xs12
                      py-4
                      align-self-end
                      text-center
                    >
                      <span v-if="item.color==true" style="color:#FFAC2C" :key="i" class="bantitle555">
                        <b >{{ item.name }}</b>
                      </span>
                          <span v-else :key="i" class="bantitle555  ">
                        <b >{{ item.name }}</b>
                      </span>
                    </v-flex>
                    <v-flex xs12 align-self-center text-center>
                      <span class="bantitle444">{{ item.title }}</span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
  
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["areas"],
  data() {
    return {
      color:false,
      webpSupported:true
    };
  },
  
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then(r => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

    })();
  } // End created
  
}
</script>
<style >

.hov:hover {
  color:#FFAC2C !important;
  cursor: pointer;
}
</style>