<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF6907"
      spinner="bar-fade-scale"
    />
    <!-- <ServerError v-if="ServerError" /> -->
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <Banner :top="top" :products="productsAndServices" :areas="areaOfFocus" />
      </v-flex>
      <v-flex xs12>
        <Clients :clients="clients"/>
      </v-flex>
      <v-flex xs12>
        <Testimonials :testimonials="testimonials"/>
      </v-flex>
      <!-- <v-flex xs12>
        <Component1 />
      </v-flex>
      <v-flex xs12>
        <Component2 />
      </v-flex>
      <v-flex xs12>
        <Component3 />
      </v-flex>
      <v-flex xs12>
        <Component4 />
      </v-flex>
      <v-flex xs12>
        <Component5 />
      </v-flex>
      <v-flex xs12>
        <Component6 />
      </v-flex> -->
    </v-layout>
  </div>
</template>
<script>
import Banner from "@/components/Home2/Banner";
import Clients from "@/components/Home2/Clients";
import Testimonials from "@/components/Home2/Testimonials";
import axios from 'axios'
export default {
  data() {
    return {
      top: [],
      testimonials:[],
      productsAndServices:[],
      clients:[],
      areaOfFocus:[],
      fullData:[],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    }
  },
  components: {
    Banner,
    Clients,
    Testimonials,
    // Component2,
    // Component3,
    // Component4,
    // Component5,
    // Component6,
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "home/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.fullData=response.data.data
            this.top=response.data.data.top
            this.testimonials=response.data.data.testimonials
            this.productsAndServices=response.data.data.productsAndServices
            this.clients=response.data.data.clients
            this.areaOfFocus=response.data.data.areaOfFocus

            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar=true
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>