<template>
  <div style="background-color: #f8f8f8 !important">
    <v-layout wrap justify-center py-16>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs12 py-2 align-self-center text-center>
            <span class="bantitle7"
              ><b> {{ clients.title }} </b></span
            >
          </v-flex>
          <v-flex xs12 align-self-center text-center>
            <v-layout wrap justify-center>
              <v-flex align-self-center sm6 lg3>
                <span class="bantitle8">
                  {{ clients.caption }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex
            xs10
            sm10
            md8
            lg8
            xl8
            py-12
            align-self-center
            v-if="clients.imageData"
          >
            <OwlCarousel
              :autoplay="true"
              :loop="true"
              :nav="true"
              :dots="true"
              :responsive="owlResponsive"
            >
              <template v-for="(item, i) in clients.imageData">
                <v-layout wrap :key="i">
                  <v-flex px-md-1 px-lg-3 px-sm-2 px-3>
                    <v-card flat color="rgba(0,0,0,0)" elevation="0">
                      <!-- <v-img contain height="80px"  :src="mediaURL+item.image">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#FF6907"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                        <v-img contain height="80px"  :src="webpSupported && item.image.split('.').pop() !== 'svg'? mediaURL+item.image : mediaURLold+item.image">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#FF6907"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex lg8>
        <v-img cover src="../../assets/Home/homeMap.png"></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["clients"],
  data() {
    return {
      owlResponsive: {
        0: { items: 2, nav: false },
        600: { items: 3, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 6, nav: false },
        2560: { items: 10, nav: false },
      },
      webpSupported: true,
    };
  },
  created() {
    (async () => {
      // If browser doesn't have createImageBitmap, we can't use webp.
      if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
      }

      // Base64 representation of a white point image
      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then(r => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

    })();
  } // End created

};
</script>