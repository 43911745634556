var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-16":"","pb-16":""}},[_c('v-flex',{attrs:{"xs12":"","lg10":"","align-self-center":""}},[_c('v-carousel',{attrs:{"cycle":"","min-height":_vm.$vuetify.breakpoint.name == 'xs'
            ? '450'
            : _vm.$vuetify.breakpoint.name == 'sm'
            ? '450'
            : _vm.$vuetify.breakpoint.name == 'md'
            ? '400'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '400'
            : '400',"hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.testimonials.data),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.src}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"bantitle9"},[_vm._v(" "+_vm._s(_vm.testimonials.topCaption)+" ")])]),_c('v-flex',{attrs:{"xs12":"","py-2":"","align-self-center":"","text-center":""}},[_c('span',{staticClass:"bantitle7"},[_c('b',[_vm._v(" "+_vm._s(_vm.testimonials.title)+" ")])])]),_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"sm10":"","md8":"","lg6":"","xl5":"","align-self-center":"","text-center":"","px-6":""}},[_c('span',{staticClass:"bantitle8",domProps:{"innerHTML":_vm._s(item.text)}})])],1)],1),_c('v-flex',{attrs:{"xs12":"","py-0":"","py-md-5":"","py-lg-12":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg12":"","align-self-center":"","text-center":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center"},[_c('span',{staticClass:"bantitle10"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-list-item-subtitle',{staticClass:"text-center"},[_c('span',{staticClass:"bantitle11"},[_vm._v(" "+_vm._s(item.company)+" ")])])],1)],1)],1)],1)],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }